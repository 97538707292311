<template>
  <div v-if="isOpen" class="modal modal-open">
    <div class="modal-box relative">
      <button class="btn btn-sm btn-circle absolute right-2 top-2" @click="closeModal">✕</button>
      <div class="container mx-auto max-w-full flex flex-col items-center justify-center">
        <h3 class="text-lg font-bold">{{ title }}</h3>
        <p class="py-4">
          <slot></slot>
        </p>
        <div class="modal-action">
          <button class="btn btn-outline" @click="closeModal">{{ closeText }}</button>
          <button
            v-if="showConfirm"
            class="btn btn-primary text-secondary"
            :disabled="isLoadingData"
            @click="confirmModal"
          >
            <span v-if="isLoadingData" class="loading loading-spinner"></span>
            {{ confirmText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Define props
defineProps({
  title: {
    type: String,
    default: ''
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  isLoadingData: {
    type: Boolean,
    default: false
  },
  showConfirm: {
    type: Boolean,
    default: true
  },
  confirmText: {
    type: String,
    default: 'Update'
  },
  closeText: {
    type: String,
    default: 'Cancel'
  }
})

// Define emits
const emit = defineEmits(['close', 'confirm'])

// Functions to handle modal actions
const closeModal = () => emit('close')
const confirmModal = () => emit('confirm')
</script>

<style scoped>
.modal-open {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
