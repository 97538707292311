<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center"
  >
    <h1 class="antialiased text-secondary text-center text-3xl md:text-4xl mt-3 mb-3">
      Vendor Profile
    </h1>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Vendor Name *</span>
      </div>
      <input
        type="text"
        class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
        placeholder=""
        v-model="vendorStore.onboardingFormData.name"
        aria-label="Vendor Name"
      />
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Vendor Type *</span>
      </div>
      <div class="w-full max-w-xs grow join join-vertical flex">
        <button
          v-for="vendorType in vendorTypes"
          :key="vendorType"
          class="btn btn-secondary join-item btn-md"
          :class="{
            'btn-outline': vendorStore.onboardingFormData.vendor_type !== vendorType.toLowerCase()
          }"
          @click="vendorStore.selectVendorType(vendorType)"
        >
          {{ vendorType }}
        </button>
      </div>
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Location *</span>
      </div>
      <GoogleAutocomplete
        class="input input-bordered w-full max-w-xs grow text-secondary placeholder-neutral border-secondary"
        placeholder="Enter Address"
        v-model="vendorStore.onboardingFormData.address"
        :api-key="apiKey"
        @set="vendorStore.updateAddressData($event)"
      />
      <div class="label" v-if="vendorStore.vendorData?.address">
        <span class="label-text text-neutral"
          >Current Address: {{ vendorStore.vendorData?.address }}</span
        >
      </div>
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Service Area</span>
      </div>
      <textarea
        class="textarea textarea-bordered w-full max-w-xs h-30 grow text-secondary placeholder-neutral border-secondary bg-primary"
        placeholder=""
        v-model="vendorStore.onboardingFormData.service_area"
        aria-label="Service Area"
      ></textarea>
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Contact Name *</span>
      </div>
      <input
        type="text"
        class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
        placeholder=""
        v-model="vendorStore.onboardingFormData.contact_person"
        aria-label="Contact Name"
      />
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Email *</span>
      </div>
      <input
        type="email"
        class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
        placeholder=""
        v-model="vendorStore.onboardingFormData.email"
        aria-label="Email"
      />
      <div class="label" v-if="vendorStore.onboardingFormData.email && !vendorStore.isEmailValid">
        <span class="label-text text-error">Invalid Email address</span>
      </div>
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Phone Number *</span>
      </div>
      <input
        type="tel"
        class="input input-bordered w-full max-w-xs text-secondary border-secondary placeholder-neutral"
        placeholder=""
        v-model="vendorStore.onboardingFormData.phone"
        aria-label="Phone Number"
      />
      <div class="label" v-if="vendorStore.onboardingFormData.phone && !vendorStore.isPhoneValid">
        <span class="label-text text-error">Phone Number is invalid</span>
      </div>
    </label>
    <label class="w-full max-w-xs mb-3">
      <div class="label">
        <span class="label-text text-secondary">Website Link</span>
      </div>
      <input
        type="text"
        class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
        placeholder=""
        v-model="vendorStore.onboardingFormData.website"
        aria-label="Website"
      />
    </label>
    <label class="w-full max-w-xs">
      <label class="label cursor-pointer">
        <span class="label-text text-secondary">Available for Booking?</span>
        <input
          type="checkbox"
          class="toggle border-secondary"
          :class="{
            'bg-primary': vendorStore.onboardingFormData.available,
            'hover:bg-primary': vendorStore.onboardingFormData.available,
            'bg-neutral': !vendorStore.onboardingFormData.available,
            'hover:bg-neutral': !vendorStore.onboardingFormData.available
          }"
          v-model="vendorStore.onboardingFormData.available"
        />
      </label>
      <!-- <span class="label-text text-neutral text-xs">^ You can update this later</span> -->
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Experience (In Years) *</span>
      </div>
      <input
        type="number"
        min="0"
        class="input input-bordered w-full max-w-xs grow text-secondary placeholder-neutral border-secondary"
        placeholder=""
        v-model="vendorStore.onboardingFormData.exp_years"
        aria-label="Experience (In Years)"
      />
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Rate (per Event) *</span>
      </div>
      <input
        type="number"
        min="0"
        class="input input-bordered w-full max-w-xs grow text-secondary placeholder-neutral border-secondary"
        placeholder=""
        v-model="vendorStore.onboardingFormData.rate_per_event"
        aria-label="Number of Shows"
      />
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Event Preferences</span>
      </div>
      <textarea
        class="textarea textarea-bordered w-full max-w-xs h-30 grow text-secondary placeholder-neutral border-secondary bg-primary"
        placeholder=""
        v-model="vendorStore.onboardingFormData.event_prefs"
        aria-label="Event Preferences"
      ></textarea>
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Facilities & Services</span>
      </div>
      <textarea
        class="textarea textarea-bordered w-full max-w-xs h-30 grow text-secondary placeholder-neutral border-secondary bg-primary"
        placeholder=""
        v-model="vendorStore.onboardingFormData.services"
        aria-label="Services"
      ></textarea>
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Links</span>
      </div>
      <textarea
        class="textarea textarea-bordered w-full max-w-xs h-30 grow text-secondary placeholder-neutral border-secondary bg-primary"
        placeholder=""
        v-model="vendorStore.onboardingFormData.links"
        aria-label="Links"
      ></textarea>
    </label>
    <div class="mt-6 flex flex-row gap-3">
      <button
        class="btn md:btn-md btn-secondary mt-2"
        :disabled="vendorStore.loadingData || !vendorStore.submissionEnabled"
        @click="onSubmitPressed"
        aria-label="Submit"
      >
        Submit
      </button>
    </div>
    <div class="mt-6">
      <h4 class="antialiased text-neutral text-center text-sm">
        Need help?
        <a
          class="text-secondary cursor-pointer"
          :href="contactLink"
          target="_blank"
          rel="noopener noreferrer"
          >Contact Us</a
        >
      </h4>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import { useVendorStore } from '@/stores/vendor'

import { ref } from 'vue'
import { GoogleAutocomplete } from 'vue3-google-autocomplete'
import { toast } from 'vue3-toastify'

const router = useRouter()
const vendorStore = useVendorStore()

const apiKey = import.meta.env.VITE_APP_GMAP_API_KEY
const vendorTypes = ref(['Food', 'Sound', 'Light', 'Stage', 'Security'])

const contactLink = computed(() => {
  return `https://wa.me/${import.meta.env.VITE_APP_WA_CONTACT}`
})

onMounted(async () => {
  if (vendorStore.venueData?.id) {
    await vendorStore.fetchVendorProfileData()
  }
})

const onSubmitPressed = async () => {
  try {
    const submitted_data = await vendorStore.profileSubmitPressed()
    if (submitted_data) {
      router.push({
        name: 'vendor-profile-detail'
      })
    }
  } catch (e) {
    toast.error(e.response?.data?.error.message)
  }
}
</script>
