<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    stroke="currentColor"
    stroke-width="1"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="h-4 w-4 opacity-70"
  >
    <path
      d="M13.359 11.237 15 12.878l-.707.707-2.236-2.236A7.441 7.441 0 0 1 8 13.5c-5 0-8-5.5-8-5.5a9.854 9.854 0 0 1 2.015-2.736L1.415 3.879l.707-.707 12.879 12.879-.707.707-1.647-1.647ZM8 11.5a3.5 3.5 0 0 1-3.317-2.417l.724-.725a2.5 2.5 0 0 0 3.285 3.285l.724-.724A3.479 3.479 0 0 1 8 11.5Zm1.5-4A1.5 1.5 0 0 0 8 6a1.5 1.5 0 0 0-1.5 1.5H6a2 2 0 0 1 2-2c.537 0 1.04.21 1.414.586l-.707.707ZM5.33 3.673l-.726.726A7.383 7.383 0 0 1 8 3c5 0 8 5.5 8 5.5-.193.308-.4.605-.615.887l-.726-.726c.175-.238.343-.484.508-.748 0 0-3-5.5-8-5.5a6.49 6.49 0 0 0-3.83 1.26Z"
    />
  </svg>
</template>
