<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center gap-2"
  >
    <h1 class="antialiased text-secondary text-center text-3xl md:text-4xl mt-3 mb-3">
      Venue Profile
    </h1>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Venue Name *</span>
      </div>
      <input
        type="text"
        class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
        placeholder=""
        v-model="venueStore.onboardingFormData.name"
        aria-label="Venue Name"
      />
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Venue Type *</span>
      </div>
      <div class="w-full max-w-xs grow join join-vertical lg:join-horizontal flex lg:items-center">
        <button
          v-for="venueType in venueTypes"
          :key="venueType"
          class="btn btn-secondary join-item btn-md"
          :class="{
            'btn-outline': venueStore.onboardingFormData.venue_type !== venueType.toLowerCase()
          }"
          @click="venueStore.selectVenueType(venueType)"
        >
          {{ venueType }}
        </button>
      </div>
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Location *</span>
      </div>
      <GoogleAutocomplete
        class="input input-bordered w-full max-w-xs grow text-secondary placeholder-neutral border-secondary"
        placeholder="Enter Address"
        v-model="venueStore.onboardingFormData.address"
        :api-key="apiKey"
        @set="venueStore.updateAddressData($event)"
      />
      <div class="label" v-if="venueStore.venueData?.address">
        <span class="label-text text-neutral"
          >Current Address: {{ venueStore.venueData?.address }}</span
        >
      </div>
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Capacity *</span>
      </div>
      <input
        type="number"
        min="10"
        class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
        placeholder=""
        v-model="venueStore.onboardingFormData.capacity"
        aria-label="Capacity"
      />
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Contact Name *</span>
      </div>
      <input
        type="text"
        class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
        placeholder=""
        v-model="venueStore.onboardingFormData.contact_person"
        aria-label="Contact Name"
      />
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Email *</span>
      </div>
      <input
        type="email"
        class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
        placeholder=""
        v-model="venueStore.onboardingFormData.email"
        aria-label="Email"
      />
      <div class="label" v-if="venueStore.onboardingFormData.email && !venueStore.isEmailValid">
        <span class="label-text text-error">Invalid Email address</span>
      </div>
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Phone Number *</span>
      </div>
      <input
        type="tel"
        class="input input-bordered w-full max-w-xs text-secondary border-secondary placeholder-neutral"
        placeholder=""
        v-model="venueStore.onboardingFormData.phone"
        aria-label="Phone Number"
      />
      <div class="label" v-if="venueStore.onboardingFormData.phone && !venueStore.isPhoneValid">
        <span class="label-text text-error">Phone Number is invalid</span>
      </div>
    </label>
    <label class="w-full max-w-xs mb-3">
      <div class="label">
        <span class="label-text text-secondary">Website Link</span>
      </div>
      <input
        type="text"
        class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
        placeholder=""
        v-model="venueStore.onboardingFormData.website"
        aria-label="Website"
      />
    </label>
    <label class="w-full max-w-xs">
      <label class="label cursor-pointer">
        <span class="label-text text-secondary">Available for Booking?</span>
        <input
          type="checkbox"
          class="toggle border-secondary"
          :class="{
            'bg-primary': venueStore.onboardingFormData.available,
            'hover:bg-primary': venueStore.onboardingFormData.available,
            'bg-neutral': !venueStore.onboardingFormData.available,
            'hover:bg-neutral': !venueStore.onboardingFormData.available
          }"
          v-model="venueStore.onboardingFormData.available"
        />
      </label>
      <!-- <span class="label-text text-neutral text-xs">^ You can update this later</span> -->
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Event Preferences</span>
      </div>
      <textarea
        class="textarea textarea-bordered w-full max-w-xs h-30 grow text-secondary placeholder-neutral border-secondary bg-primary"
        placeholder=""
        v-model="venueStore.onboardingFormData.event_prefs"
        aria-label="Event Preferences"
      ></textarea>
    </label>
    <label class="w-full max-w-xs">
      <div class="label">
        <span class="label-text text-secondary">Facilities & Services</span>
      </div>
      <textarea
        class="textarea textarea-bordered w-full max-w-xs h-30 grow text-secondary placeholder-neutral border-secondary bg-primary"
        placeholder=""
        v-model="venueStore.onboardingFormData.facilities"
        aria-label="Portfolio & Links"
      ></textarea>
    </label>
    <div class="mt-6 flex flex-row gap-3">
      <button
        class="btn md:btn-md btn-secondary mt-2"
        :disabled="venueStore.loadingData || !venueStore.submissionEnabled"
        @click="onSubmitPressed"
        aria-label="Submit"
      >
        Submit
      </button>
    </div>
    <div class="mt-6">
      <h4 class="antialiased text-neutral text-center text-sm">
        Need help?
        <a
          class="text-secondary cursor-pointer"
          :href="contactLink"
          target="_blank"
          rel="noopener noreferrer"
          >Contact Us</a
        >
      </h4>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useVenueStore } from '@/stores/venue'

import { ref } from 'vue'
import { GoogleAutocomplete } from 'vue3-google-autocomplete'
import { toast } from 'vue3-toastify'

const router = useRouter()
const venueStore = useVenueStore()

const apiKey = import.meta.env.VITE_APP_GMAP_API_KEY
const venueTypes = ref(['Indoor', 'Outdoor', 'Hybrid'])

const contactLink = computed(() => {
  return `https://wa.me/${import.meta.env.VITE_APP_WA_CONTACT}`
})

onMounted(async () => {
  if (venueStore.venueData?.id) {
    await venueStore.fetchVenueProfileData()
  }
})

const onSubmitPressed = async () => {
  try {
    const submitted_data = await venueStore.profileSubmitPressed()
    if (submitted_data) {
      router.push({
        name: 'venue-profile-detail'
      })
    }
  } catch (e) {
    toast.error(e.response?.data?.error.message)
  }
}
</script>
