<template>
  <section>
    <h2 class="text-2xl font-semibold mb-2">Vendors</h2>
    <div class="flex overflow-x-scroll space-x-4 pb-4" v-if="vendors.length">
      <div
        v-for="vendor in vendors"
        :key="vendor.id"
        class="flex-none w-48 bg-gray-800 rounded-lg shadow-lg transform hover:scale-105 transition"
        @click="redirectToVendorDetail(vendor)"
      >
        <img
          v-if="vendor.profile_photo"
          :src="parsePhotoUrl(vendor.profile_photo)"
          :alt="vendor.name"
          class="w-full h-48 rounded-t-lg object-cover"
        />
        <div class="p-4">
          <h3 class="text-lg font-medium">{{ vendor.name }}</h3>
          <p class="text-sm text-gray-400">{{ vendor.address }}</p>
          <p class="text-sm text-gray-400">{{ vendor.vendor_type }}</p>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col items-center justify-center h-64 text-center text-secondary border-2 border-secondary border-dashed"
    >
      <p class="text-lg font-medium">No entries available yet</p>
      <p class="text-sm">Check back later or explore other sections.</p>
    </div>
  </section>
</template>

<script setup>
import { parsePhotoUrl } from '@/utils/transformers'
import { useRouter } from 'vue-router'

const router = useRouter()

defineProps({
  vendors: {
    type: Array,
    required: true,
    default: () => []
  }
})

const redirectToVendorDetail = (vendor) => {
  router.push({
    name: 'vendor-detail',
    params: {
      slug: vendor.slug
    }
  })
}
</script>
