<template>
  <div class="min-h-screen bg-primary text-secondary p-4 ml-4 mr-4">
    <h1 class="text-3xl md:text-4xl font-bold">Explore Our Collections</h1>
    <div class="flex flex-col items-center justify-center" v-if="loadingData">
      <LoadingRing :loading="loadingData" />
    </div>
    <div v-else>
      <ArtistCollection class="mt-10" :artists="artistStore.artists" />
      <VenueCollection class="mt-10" :venues="venueStore.venues" />
      <VendorCollection class="mt-10" :vendors="vendorStore.vendors" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue'

import ArtistCollection from '@/components/artist/ArtistCollection.vue'
import VenueCollection from '@/components/venue/VenueCollection.vue'
import VendorCollection from '@/components/vendor/VendorCollection.vue'

import LoadingRing from '@/components/LoadingRing.vue'

import { useArtistStore } from '@/stores/artist'
import { useVenueStore } from '@/stores/venue'
import { useVendorStore } from '@/stores/vendor'

const artistStore = useArtistStore()
const venueStore = useVenueStore()
const vendorStore = useVendorStore()

onMounted(async () => {
  await artistStore.fetchAllArtists()
  await venueStore.fetchAllVenues()
  await vendorStore.fetchAllVendors()
})

const loadingData = computed(() => {
  return artistStore.loadingData || venueStore.loadingData || vendorStore.loadingData
})
</script>
