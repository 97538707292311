<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center prose"
  >
    <h1 class="antialiased text-secondary text-center text-3xl md:text-4xl mt-0 mb-2">
      Vendor Profile
    </h1>
    <div class="flex flex-col items-center mt-6 space-y-2">
      <!-- Avatar Image -->
      <div
        class="w-32 h-32 rounded-full overflow-hidden border-4 border-secondary flex items-center"
        @click="openPhotoUploadModal"
      >
        <img
          v-if="vendorStore.vendorData?.profile_photo"
          :src="vendorStore.profilePhotoUrl"
          alt="Profile Photo"
          class="object-cover w-full h-full"
        />
        <div v-else class="w-full h-full flex items-center justify-center bg-neutral text-white">
          Upload photo
        </div>
      </div>
      <!-- Profile Information -->
      <div class="text-center">
        <h2 class="text-xl font-semibold text-secondary mt-2 mb-0">
          {{ vendorStore.vendorData?.name }}
        </h2>
        <h4 class="antialiased text-neutral text-center text-sm mb-6">
          {{ vendorStore.vendorData?.address }} -
          <a class="text-secondary cursor-pointer" @click="openContactModal">Contact Info</a>
        </h4>
        <p class="text-secondary">
          <span class="opacity-80">Vendor Type</span><br />
          <span class="uppercase">{{ vendorStore.vendorData?.vendor_type }}</span>
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Experience</span> <br />
          {{ vendorStore.vendorData?.exp_years || 0 }}
          {{
            vendorStore.vendorData?.exp_years && vendorStore.vendorData?.exp_years > 1
              ? 'years'
              : 'year'
          }}
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Rate Per Event</span> <br />
          ৳ {{ vendorStore.vendorData?.rate_per_event || 0 }}
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Service Area</span> <br />
          {{ vendorStore.vendorData?.service_area || 'No info provided' }}
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Facilities & Services</span> <br />
          {{ vendorStore.vendorData?.services || 'No info provided' }}
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Event Preferences</span> <br />
          {{ vendorStore.vendorData?.event_prefs || 'No info provided' }}
        </p>
      </div>
    </div>
    <div class="mt-6 flex flex-row gap-3">
      <button
        class="btn md:btn-md btn-outline btn-secondary mt-2"
        @click="editProfile"
        aria-label="Edit Profile"
      >
        Edit Profile
      </button>
      <button
        class="btn md:btn-md btn-outline btn-secondary mt-2"
        @click="redirectToExplore"
        aria-label="Edit Profile"
      >
        Explore
      </button>
    </div>
  </div>
  <ModalBase
    :isOpen="isPhotoUploadModalOpen"
    :isLoadingData="vendorStore.loadingData"
    title="Update Profile Photo"
    @close="closePhotoUploadModal"
    @confirm="handleProfilePhotoUpload"
  >
    <label
      for="photo-upload"
      class="w-full max-w-xs relative cursor-pointer hover:opacity-90 transition"
    >
      <div
        class="w-32 h-32 rounded-full overflow-hidden border-4 border-secondary flex items-center"
        v-if="vendorStore.profilePhotoFile"
      >
        <img
          :src="vendorStore.profilePhotoFileUrl"
          alt="Profile Photo"
          class="object-cover w-full h-full"
        />
      </div>
      <div
        class="flex flex-col items-center p-5 border-2 border-dashed border-neutral rounded-lg hover:bg-opacity-75"
        v-else
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="w-12 h-12 text-primary mb-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 3v18h18V3H3zm4 7l5 5m0 0l5-5m-5 5V9"
          />
        </svg>
        <span class="text-neutral text-center"
          >Drag & drop / click here to upload your profile photo</span
        >
      </div>
      <input
        id="photo-upload"
        type="file"
        class="hidden"
        accept="image/*"
        @change="selectProfilePhoto"
        aria-label="Upload Profile Photo"
      />
    </label>
  </ModalBase>
  <ModalBase
    :isOpen="isContactModalOpen"
    title="Contact Information"
    :showConfirm="false"
    closeText="Close"
    @close="closeContactModal"
  >
    <div class="flex flex-col gap-4 text-center">
      <p>
        <span class="opacity-80">Contact Person</span> <br />
        {{ vendorStore.vendorData?.contact_person }}
      </p>
      <p>
        <span class="opacity-80">Phone Number</span> <br />
        {{ vendorStore.vendorData?.phone }}
      </p>
      <p>
        <span class="opacity-80">Email</span> <br />
        {{ vendorStore.vendorData?.email }}
      </p>
      <p>
        <span class="opacity-80">Address</span> <br />
        {{ vendorStore.vendorData?.address }}
      </p>
      <p>
        <span class="opacity-80">Website</span> <br />
        <a
          class="link cursor-pointer"
          :href="vendorStore.vendorData?.website"
          target="_blank"
          rel="noopener noreferrer"
          >{{ vendorStore.vendorData?.website }}</a
        >
      </p>
    </div>
  </ModalBase>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useVendorStore } from '@/stores/vendor'
import ModalBase from '@/components/ModalBase.vue'

const router = useRouter()
const vendorStore = useVendorStore()

const isPhotoUploadModalOpen = ref(false)
const isContactModalOpen = ref(false)

onMounted(async () => {
  if (vendorStore.vendorData?.id) {
    await vendorStore.fetchVendorProfileData()
  }
})

const editProfile = () => {
  router.push({
    name: 'vendor-profile-edit'
  })
}

const redirectToExplore = () => {
  router.push({
    name: 'explore'
  })
}

const openPhotoUploadModal = () => {
  vendorStore.unsetSelectProfilePhoto()
  isPhotoUploadModalOpen.value = true
}

const closePhotoUploadModal = () => {
  isPhotoUploadModalOpen.value = false
}

const openContactModal = () => {
  isContactModalOpen.value = true
}

const closeContactModal = () => {
  isContactModalOpen.value = false
}

const selectProfilePhoto = (event) => {
  const file = event.target.files[0]
  if (file) {
    vendorStore.selectProfilePhoto(file)
    console.log('Updated profile photo file:', file)
  }
}

const handleProfilePhotoUpload = async () => {
  console.log('Updating profile photo file:', vendorStore.profilePhotoFile)
  await vendorStore.uploadProfilePhoto()
  closePhotoUploadModal()
}
</script>
