import { defineStore } from 'pinia'
import axios from 'axios'

import { useAuthStore } from './auth'

export const useVenueStore = defineStore('venue', {
  state: () => ({
    loadingData: false,
    profilePhotoFile: null,
    onboardingFormData: JSON.parse(localStorage.getItem('venue_data')) || {
      name: '',
      email: '',
      phone: '',
      contact_person: '',
      venue_type: 'indoor',
      website: '',
      facilities: '',
      event_prefs: '',
      agreed_terms: false,
      available: true,
      location_lat: '',
      location_lon: '',
      address: '',
      capacity: 0
    },
    venueData: JSON.parse(localStorage.getItem('venue_data')) || null,
    venues: []
  }),
  getters: {
    isPhoneValid: (state) => {
      return /^(?:\+88)?01[3-9][0-9]{8}$/.test(state.onboardingFormData.phone)
    },
    isEmailValid: (state) => {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(state.onboardingFormData.email)
    },
    profileDataUpdated: (state) => {
      return JSON.stringify(state.venueData) !== JSON.stringify(state.onboardingFormData)
    },
    submissionEnabled: (state) => {
      // const needsUpdating = state.onLastOnboardingState && state.profileDataUpdated
      return state.validateOnboarding() //&& needsUpdating
    },
    profilePhotoUrl: (state) => {
      return state.venueData?.profile_photo?.formats
        ? import.meta.env.VITE_APP_BASE_URL + state.venueData.profile_photo.formats.small.url
        : state.venueData?.profile_photo
          ? state.venueData?.profile_photo.url
          : null
    },
    profilePhotoFileUrl: (state) => {
      return state.profilePhotoFile ? URL.createObjectURL(state.profilePhotoFile) : null
    }
  },
  actions: {
    async uploadFile(file, refId, ref, field, path = null) {
      this.loadingData = true

      const authStore = useAuthStore()

      try {
        const formData = new FormData()

        formData.append('files', file)

        // Add additional metadata for the file association
        formData.append('refId', refId) // ID of the related entry (e.g., user ID)
        formData.append('ref', ref) // Model name (e.g., user, artist, ticket)
        formData.append('field', field) // Field where the file will be stored (e.g., profile_photo)

        if (path) {
          formData.append('path', path) // Custom path for file upload
        }

        const response = await axios.post(`${import.meta.env.VITE_APP_API_URL}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authStore.token}`
          }
        })

        console.log('File upload response:', response.data)
        return response.data
      } catch (error) {
        console.error('File upload failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      } finally {
        this.loadingData = false
      }
    },
    async createVenueProfile() {
      const authStore = useAuthStore()

      try {
        this.loadingData = true

        const response = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/venues`,
          {
            data: {
              ...this.onboardingFormData,
              created_user: authStore.userData?.id
            }
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`
            }
          }
        )
        console.log(response.data)

        this.venueData = response.data.data
        this.onboardingFormData = { ...this.venueData }
      } catch (error) {
        console.error('Venue Creation failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      } finally {
        this.loadingData = false
      }
    },
    async updateVenueProfile() {
      const authStore = useAuthStore()

      try {
        this.loadingData = true

        const response = await axios.put(
          `${import.meta.env.VITE_APP_API_URL}/venues/${this.venueData?.id}`,
          {
            data: {
              ...this.onboardingFormData
            }
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`
            }
          }
        )
        console.log(response.data)

        this.venueData = response.data.data
        this.onboardingFormData = { ...this.venueData }
      } catch (error) {
        console.error('Venue Update failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      } finally {
        this.loadingData = false
      }
    },
    async fetchAllVenues() {
      try {
        const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/venues`, {
          params: {
            populate: '*'
          }
        })
        console.log(response.data)
        this.venues = response.data.data
        return this.venues
      } catch (error) {
        console.error('Venues Fetch failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      }
    },
    async fetchVenueProfileData() {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_APP_API_URL}/venues/${this.venueData?.id}`,
          {
            params: {
              populate: '*'
            }
          }
        )
        console.log(response.data)
        this.venueData = response.data.data
        this.onboardingFormData = { ...this.venueData }
        return this.venueData
      } catch (error) {
        console.error('Venue Fetch failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      }
    },
    async fetchVenueProfileByCreatedUser(userId) {
      const authStore = useAuthStore()

      try {
        const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/venues/`, {
          headers: {
            Authorization: `Bearer ${authStore.token}`
          },
          params: {
            populate: '*',
            filters: {
              created_user: {
                id: {
                  $eq: userId // Check for equality with the user ID
                }
              }
            }
          }
        })
        console.log(response.data)
        if (response.data.data.length) {
          this.venueData = response.data.data[0]
          this.onboardingFormData = { ...this.venueData }
        }
        return this.venueData
      } catch (error) {
        console.error('Venue Fetch failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      }
    },
    async fetchVenueProfileBySlug(slug) {
      try {
        const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/venues/`, {
          params: {
            populate: '*',
            filters: {
              slug: {
                $eq: slug
              }
            }
          }
        })
        console.log(response.data)
        if (response.data.data.length) {
          this.venueData = response.data.data[0]
          // this.onboardingFormData = { ...this.venueData }
        }
        return this.venueData
      } catch (error) {
        console.error('Venue Fetch failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      }
    },
    validateOnboarding() {
      const reqFields = ['name', 'venue_type', 'contact_person', 'email', 'phone', 'capacity']
      for (const field of reqFields) {
        if (!this.onboardingFormData[field]) {
          return false
        }
      }

      if (!this.isEmailValid || !this.isPhoneValid) {
        return false
      }

      return true
    },
    async profileSubmitPressed() {
      console.log('submit data api : ' + this.venueData === null ? 'created' : 'updated')
      try {
        if (this.venueData === null) {
          await this.createVenueProfile()
        } else {
          await this.updateVenueProfile()
        }

        return true
      } catch (e) {
        return false
      }
    },
    async uploadProfilePhoto() {
      await this.uploadFile(
        this.profilePhotoFile,
        this.venueData?.id,
        'api::venue.venue',
        'profile_photo'
      )

      if (this.venueData?.id) {
        await this.fetchVenueProfileData()
      }
    },
    selectVenueType(venue_type) {
      this.onboardingFormData.venue_type = venue_type.toLowerCase()
    },
    updateAddressData(data) {
      this.onboardingFormData.location_lat = data.latitude
      this.onboardingFormData.location_lon = data.longitude

      var addressParts = [data.name, data.city, data.country].filter(Boolean)
      addressParts = Array.from(new Set(addressParts))
      this.onboardingFormData.address = addressParts.map((str) => str.trim()).join(', ')
    },
    selectProfilePhoto(file) {
      this.profilePhotoFile = file
    },
    unsetSelectProfilePhoto() {
      this.profilePhotoFile = null
    }
  }
})

// Sync state changes to localStorage
export function setupVenueStorePersistence() {
  const store = useVenueStore()

  store.$subscribe((mutation, state) => {
    if (state.venueData) {
      localStorage.setItem('venue_data', JSON.stringify(state.venueData))
    }

    // if (state.onboardingFormData) {
    //   localStorage.setItem('onboarding_form_data', JSON.stringify(state.onboardingFormData))
    // }
  })
}
