<template>
  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Stage Name *</span>
    </div>
    <input
      type="text"
      class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
      placeholder=""
      v-model="artistStore.onboardingFormData.stage_name"
      aria-label="Stage Name"
    />
  </label>
  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Contact Person *</span>
    </div>
    <input
      type="text"
      class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
      placeholder=""
      v-model="artistStore.onboardingFormData.full_name"
      aria-label="Stage Name"
    />
  </label>
  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Email *</span>
    </div>
    <input
      type="email"
      class="input input-bordered w-full max-w-xs grow text-secondary border-secondary placeholder-neutral"
      placeholder=""
      v-model="artistStore.onboardingFormData.email"
      aria-label="Email"
    />
    <div class="label" v-if="artistStore.onboardingFormData.email && !artistStore.isEmailValid">
      <span class="label-text text-error">Invalid Email address</span>
    </div>
  </label>
  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Phone Number *</span>
    </div>
    <input
      type="tel"
      pattern="^(?:\+88)?01[3-9][0-9]{8}$"
      class="input input-bordered w-full max-w-xs text-secondary border-secondary placeholder-neutral"
      placeholder=""
      v-model="artistStore.onboardingFormData.phone"
      aria-label="Phone Number"
    />
    <div class="label" v-if="artistStore.onboardingFormData.phone && !artistStore.isPhoneValid">
      <span class="label-text text-error">Phone Number is invalid</span>
    </div>
  </label>
</template>

<script setup>
import { useArtistStore } from '@/stores/artist'

const artistStore = useArtistStore()
</script>
