<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center prose"
  >
    <h1 class="antialiased text-secondary text-center text-3xl md:text-4xl mt-0 mb-2">
      Vendor Profile
    </h1>
    <div class="flex flex-col items-center mt-6 space-y-2">
      <!-- Avatar Image -->
      <div
        class="w-32 h-32 rounded-full overflow-hidden border-4 border-secondary flex items-center"
      >
        <img
          v-if="vendorStore.vendorData?.profile_photo"
          :src="vendorStore.profilePhotoUrl"
          alt="Profile Photo"
          class="object-cover w-full h-full"
        />
        <div v-else class="w-full h-full flex items-center justify-center bg-neutral text-white">
          No photo yet
        </div>
      </div>
      <!-- Profile Information -->
      <div class="text-center">
        <h2 class="text-xl font-semibold text-secondary mt-2 mb-0">
          {{ vendorStore.vendorData?.name }}
        </h2>
        <h4 class="antialiased text-neutral text-center text-sm mb-6">
          {{ vendorStore.vendorData?.address }} -
          <a class="text-secondary cursor-pointer" @click="openContactModal">Contact Info</a>
        </h4>
        <p class="text-secondary">
          <span class="opacity-80">Vendor Type</span><br />
          <span class="uppercase">{{ vendorStore.vendorData?.vendor_type }}</span>
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Experience</span> <br />
          {{ vendorStore.vendorData?.exp_years || 0 }}
          {{
            vendorStore.vendorData?.exp_years && vendorStore.vendorData?.exp_years > 1
              ? 'years'
              : 'year'
          }}
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Rate Per Event</span> <br />
          ৳ {{ vendorStore.vendorData?.rate_per_event || 0 }}
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Service Area</span> <br />
          {{ vendorStore.vendorData?.service_area || 'No info provided' }}
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Facilities & Services</span> <br />
          {{ vendorStore.vendorData?.services || 'No info provided' }}
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Event Preferences</span> <br />
          {{ vendorStore.vendorData?.event_prefs || 'No info provided' }}
        </p>
      </div>
    </div>
    <div class="mt-6 flex flex-row gap-3">
      <!-- <button
        class="btn md:btn-md btn-outline btn-secondary mt-2"
        @click="editProfile"
        aria-label="Edit Profile"
      >
        Edit Profile
      </button> -->
      <button
        class="btn md:btn-md btn-outline btn-secondary mt-2"
        @click="redirectToExplore"
        aria-label="Edit Profile"
      >
        Back to Collection
      </button>
    </div>
  </div>
  <ModalBase
    :isOpen="isContactModalOpen"
    title="Contact Information"
    :showConfirm="false"
    closeText="Close"
    @close="closeContactModal"
  >
    <div class="flex flex-col gap-4 text-center">
      <p>
        <span class="opacity-80">Contact Person</span> <br />
        {{ vendorStore.vendorData?.contact_person }}
      </p>
      <p>
        <span class="opacity-80">Phone Number</span> <br />
        {{ vendorStore.vendorData?.phone }}
      </p>
      <p>
        <span class="opacity-80">Email</span> <br />
        {{ vendorStore.vendorData?.email }}
      </p>
      <p>
        <span class="opacity-80">Address</span> <br />
        {{ vendorStore.vendorData?.address }}
      </p>
      <p>
        <span class="opacity-80">Website</span> <br />
        <a
          class="link cursor-pointer"
          :href="vendorStore.vendorData?.website"
          target="_blank"
          rel="noopener noreferrer"
          >{{ vendorStore.vendorData?.website }}</a
        >
      </p>
    </div>
  </ModalBase>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { useVendorStore } from '@/stores/vendor'
import ModalBase from '@/components/ModalBase.vue'

const router = useRouter()
const route = useRoute()

const vendorStore = useVendorStore()

const isContactModalOpen = ref(false)

onMounted(async () => {
  const vendorSlug = route.params.slug
  await vendorStore.fetchVendorProfileBySlug(vendorSlug)
})

// const editProfile = () => {
//   router.push({
//     name: 'vendor-profile-edit'
//   })
// }

const redirectToExplore = () => {
  router.push({
    name: 'explore'
  })
}

const openContactModal = () => {
  isContactModalOpen.value = true
}

const closeContactModal = () => {
  isContactModalOpen.value = false
}
</script>
