<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center prose"
  >
    <h1 class="antialiased text-secondary text-center text-3xl md:text-4xl mb-2">Artist Profile</h1>
    <div class="flex flex-col items-center mt-6 space-y-2">
      <!-- Avatar Image -->
      <div
        class="w-32 h-32 rounded-full overflow-hidden border-4 border-secondary flex items-center"
      >
        <img
          v-if="artistStore.artistData?.profile_photo"
          :src="artistStore.profilePhotoUrl"
          alt="Profile Photo"
          class="object-cover w-full h-full"
        />
        <div v-else class="w-full h-full flex items-center justify-center bg-neutral text-white">
          No photo yet
        </div>
      </div>
      <!-- Profile Information -->
      <div class="text-center">
        <h2 class="text-xl font-semibold text-secondary mt-2 mb-0">
          {{ artistStore.artistData?.stage_name }}
        </h2>
        <h4 class="antialiased text-neutral text-center text-sm mb-6">
          {{ artistStore.artistData?.address }} -
          <a class="text-secondary cursor-pointer" @click="openContactModal">Contact Info</a>
        </h4>
        <p class="text-secondary">
          <span class="opacity-80">Performance Style</span><br />
          <span class="uppercase">{{ artistStore.artistData?.performance_style }}</span>
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Genre</span> <br />
          {{ artistStore.artistData?.genre || 'No Genre Specified' }}
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Experience</span> <br />
          {{ artistStore.artistData?.exp_years || 0 }}
          {{
            artistStore.artistData?.exp_years && artistStore.artistData?.exp_years > 1
              ? 'years'
              : 'year'
          }}
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Number of Shows</span> <br />
          {{ artistStore.artistData?.num_shows || 0 }}
        </p>
        <p class="text-secondary">
          <span class="opacity-80">Price Range</span> <br />
          ৳{{ artistStore.artistData?.price_range_min || 0 }} - ৳{{
            artistStore.artistData?.price_range_max || 'N/A'
          }}
        </p>
      </div>
    </div>
    <div class="mt-6 flex flex-row gap-3">
      <!-- <button
        class="btn md:btn-md btn-outline btn-secondary mt-2"
        @click="editProfile"
        aria-label="Edit Profile"
      >
        Edit Profile
      </button> -->
      <button
        class="btn md:btn-md btn-outline btn-secondary mt-2"
        @click="redirectToExplore"
        aria-label="Edit Profile"
      >
        Back to Collection
      </button>
    </div>
  </div>
  <ModalBase
    :isOpen="isContactModalOpen"
    title="Contact Information"
    :showConfirm="false"
    closeText="Close"
    @close="closeContactModal"
  >
    <div class="flex flex-col gap-2 text-left">
      <p>
        <span class="opacity-80">Contact Person</span> : {{ artistStore.artistData?.full_name }}
      </p>
      <p><span class="opacity-80">Phone Number</span> : {{ artistStore.artistData?.phone }}</p>
      <p><span class="opacity-80">Email</span> : {{ artistStore.artistData?.email }}</p>
      <p><span class="opacity-80">Address</span> : {{ artistStore.artistData?.address }}</p>
    </div>
  </ModalBase>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { useArtistStore } from '@/stores/artist'
import ModalBase from '@/components/ModalBase.vue'

const route = useRoute()
const router = useRouter()
const artistStore = useArtistStore()

const isContactModalOpen = ref(false)

onMounted(async () => {
  const artistSlug = route.params.slug
  await artistStore.fetchArtistProfileBySlug(artistSlug)
})

// const editProfile = () => {
//   router.push({
//     name: 'artist-profile-edit'
//   })
// }

const redirectToExplore = () => {
  router.push({
    name: 'explore'
  })
}

const openContactModal = () => {
  isContactModalOpen.value = true
}

const closeContactModal = () => {
  isContactModalOpen.value = false
}
</script>
