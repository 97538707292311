import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

import TicketRegView from '@/views/TicketRegView.vue'
import TicketPreview from '@/views/TicketPreview.vue'
import TicketValidationView from '@/views/TicketValidationView.vue'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import RegistrationView from '@/views/RegistrationView.vue'
import OnboardingView from '@/views/OnboardingView.vue'
import ArtistProfileView from '@/views/ArtistProfileView.vue'
import VenueProfileView from '@/views/VenueProfileView.vue'
import VendorProfileView from '@/views/VendorProfileView.vue'
import ArtistProfileDetailView from '@/views/ArtistProfileDetailView.vue'
import VenueProfileDetailView from '@/views/VenueProfileDetailView.vue'
import VendorProfileDetailView from '@/views/VendorProfileDetailView.vue'
import ExploreView from '@/views/ExploreView.vue'
import ArtistDetailView from '@/views/ArtistDetailView.vue'
import VendorDetailView from '@/views/VendorDetailView.vue'
import VenueDetailView from '@/views/VenueDetailView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      // redirect: '/flood-aid/tickets/'
      name: 'home',
      component: HomeView
    },
    {
      path: '/explore',
      name: 'explore',
      component: ExploreView
    },
    {
      path: '/artists/:slug/detail',
      name: 'artist-detail',
      component: ArtistDetailView
    },
    {
      path: '/venues/:slug/detail',
      name: 'venue-detail',
      component: VenueDetailView
    },
    {
      path: '/vendors/:slug/detail',
      name: 'vendor-detail',
      component: VendorDetailView
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/registration',
      name: 'registration',
      component: RegistrationView
    },
    {
      path: '/onboard',
      name: 'onboard',
      component: OnboardingView,
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/me/artist/edit',
      name: 'artist-profile-edit',
      component: ArtistProfileView,
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/me/artist',
      name: 'artist-profile-detail',
      component: ArtistProfileDetailView,
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/me/venue/edit',
      name: 'venue-profile-edit',
      component: VenueProfileView,
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/me/venue',
      name: 'venue-profile-detail',
      component: VenueProfileDetailView,
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/me/vendor/edit',
      name: 'vendor-profile-edit',
      component: VendorProfileView,
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/me/vendor',
      name: 'vendor-profile-detail',
      component: VendorProfileDetailView,
      meta: { requiresAuth: true }
    },
    {
      path: '/flood-aid/tickets/',
      name: 'flood-aid-registration',
      component: TicketRegView
    },
    {
      path: '/flood-aid/tickets/:id/preview',
      name: 'flood-aid-ticket-preview',
      component: TicketPreview
    },
    {
      path: '/flood-aid/tickets/:id/validate',
      name: 'flood-aid-ticket-validation',
      component: TicketValidationView,
      meta: { requiresAuth: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!authStore.isAuthenticated) {
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  }
  next()
})

export default router
