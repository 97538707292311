<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center prose"
  >
    <h1 class="antialiased text-secondary text-center text-3xl md:text-4xl">Login</h1>
    <div>
      <label
        class="input input-bordered flex items-center gap-3 mb-3 text-secondary border-secondary"
      >
        <IconEmail />
        <input
          type="text"
          class="grow text-secondary placeholder-neutral"
          placeholder="email"
          v-model="identity"
          aria-label="Email"
        />
      </label>
      <label class="input input-bordered flex items-center gap-3 text-secondary border-secondary">
        <IconKey />
        <input
          :type="isPasswordVisible ? 'text' : 'password'"
          class="grow text-secondary placeholder-neutral"
          placeholder="password"
          v-model="password"
        />
        <button type="button" class="right-3 text-secondary" @click="togglePasswordVisibility">
          <IconEye v-if="isPasswordVisible" />
          <IconEyeOff v-else />
        </button>
      </label>
    </div>
    <div class="mt-3">
      <button
        class="btn md:btn-md btn-outline btn-secondary mt-2"
        :disabled="!hasValidData || authStore.loadingData"
        @click="loginAction"
        aria-label="Login"
      >
        <span v-if="authStore.loadingData" class="loading loading-spinner"></span>
        LOGIN
      </button>
    </div>

    <div class="mt-3">
      <h4 class="antialiased text-neutral text-center text-sm">
        New User?
        <a @click="redirectToRegistration" class="text-secondary cursor-pointer">Register</a>
      </h4>
      <h4 class="antialiased text-neutral text-center text-sm">
        Just Roaming around?
        <a @click="redirectToExplore" class="text-secondary cursor-pointer">Explore</a>
      </h4>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { toast } from 'vue3-toastify'

import IconEmail from '@/components/icons/IconEmail.vue'
import IconEye from '@/components/icons/IconEye.vue'
import IconEyeOff from '@/components/icons/IconEyeOff.vue'
import IconKey from '@/components/icons/IconKey.vue'

const identity = ref('')
const password = ref('')
const isPasswordVisible = ref(false)

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()

onMounted(() => {
  if (authStore.isAuthenticated) {
    if (authStore.entityType) {
      router.push({
        name: `${authStore.entityType}-profile-detail`
      })
    } else {
      router.push({
        name: 'onboard'
      })
    }
  }
})

const hasValidData = computed(() => {
  return !!identity.value.trim() && !!password.value.trim()
})

const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value
}

const redirectToRegistration = () => {
  router.push({
    name: 'registration'
  })
}

const redirectToExplore = () => {
  router.push({
    name: 'explore'
  })
}

const loginAction = async () => {
  try {
    await authStore.login(identity.value, password.value)

    if (authStore.isAuthenticated) {
      const redirectTo = route.query.redirect || { name: 'onboard' }
      router.push(redirectTo)
    }
  } catch (e) {
    toast.error(e.response?.data?.error.message)
  }
}
</script>
