<template>
  <svg
    viewBox="0 0 24 24"
    class="h-6 w-6 text-secondary"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M10 12H20M20 12L17 9M20 12L17 15"
        stroke="#ff9c40"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M4 12C4 7.58172 7.58172 4 12 4M12 20C9.47362 20 7.22075 18.8289 5.75463 17"
        stroke="#ff9c40"
        stroke-width="1.5"
        stroke-linecap="round"
      ></path>
    </g>
  </svg>
</template>
