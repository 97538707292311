<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center prose"
    v-if="!authStore.entityType"
  >
    <h1 class="antialiased text-secondary text-center text-2xl md:text-3xl">Hi, who are you?</h1>
    <div class="mt-4 lg:mt-2">
      <div class="join join-vertical lg:join-horizontal flex lg:items-center">
        <button
          v-for="entityTypeOpt in authStore.entityTypeOpts"
          :key="entityTypeOpt"
          class="btn btn-secondary btn-secondary join-item btn-lg"
          :class="{
            'btn-outline': authStore.selectedEntityType !== entityTypeOpt.toLowerCase()
          }"
          @click="authStore.selectEntityType(entityTypeOpt)"
        >
          {{ entityTypeOpt }}
        </button>
      </div>
    </div>
    <div class="mt-6 flex flex-row">
      <button
        class="btn md:btn-md btn-secondary mt-2"
        :disabled="authStore.loadingData"
        @click="onSubmitPressed"
        aria-label="Submit"
      >
        <span v-if="authStore.loadingData" class="loading loading-spinner"></span>
        Submit
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useArtistStore } from '@/stores/artist'
import { useVenueStore } from '@/stores/venue'
import { useVendorStore } from '@/stores/vendor'

const router = useRouter()

const authStore = useAuthStore()
const artistStore = useArtistStore()
const venueStore = useVenueStore()
const vendorStore = useVendorStore()

const redirectToProfile = async () => {
  switch (authStore.entityType) {
    case 'artist':
      await artistStore.fetchArtistProfileByCreatedUser(authStore.userData?.id)
      router.push({
        name: artistStore.artistData ? 'artist-profile-detail' : 'artist-profile-edit'
      })
      break
    case 'venue':
      await venueStore.fetchVenueProfileByCreatedUser(authStore.userData?.id)
      router.push({
        name: venueStore.venueData ? 'venue-profile-detail' : 'venue-profile-edit'
      })
      break
    case 'vendor':
      await vendorStore.fetchVendorProfileByCreatedUser(authStore.userData?.id)
      router.push({
        name: vendorStore.vendorData ? 'vendor-profile-detail' : 'vendor-profile-edit'
      })
      break
    default:
      break
  }
}

onMounted(async () => {
  if (authStore.isAuthenticated && authStore.entityType) {
    redirectToProfile()
  }
})

const onSubmitPressed = async () => {
  await authStore.onboardingSubmitPressed()
  redirectToProfile()
}
</script>
