<template>
  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Price Range (In BDT per show)</span>
    </div>
    <div class="flex flex-row gap-1 items-center w-full max-w-xs">
      <input
        type="number"
        min="1"
        class="input input-bordered w-20 md:w-40 grow text-secondary placeholder-neutral border-secondary"
        placeholder=""
        v-model="artistStore.onboardingFormData.price_range_min"
        aria-label="Price Range Minimum"
      />
      <input
        type="number"
        min="1"
        class="input input-bordered w-20 md:w-40 grow text-secondary placeholder-neutral border-secondary"
        placeholder=""
        v-model="artistStore.onboardingFormData.price_range_max"
        aria-label="Price Range Maximum"
      />
    </div>
  </label>
  <label class="w-full max-w-xs">
    <label class="label cursor-pointer">
      <span class="label-text text-secondary">Available for Booking?</span>
      <input
        type="checkbox"
        class="toggle border-secondary"
        :class="{
          'bg-primary': artistStore.onboardingFormData.available,
          'hover:bg-primary': artistStore.onboardingFormData.available,
          'bg-neutral': !artistStore.onboardingFormData.available,
          'hover:bg-neutral': !artistStore.onboardingFormData.available
        }"
        v-model="artistStore.onboardingFormData.available"
      />
    </label>
    <!-- <span class="label-text text-neutral text-xs">^ You can update this later</span> -->
  </label>
  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Gig Preferences</span>
    </div>
    <textarea
      class="textarea textarea-bordered w-full max-w-xs h-30 grow text-secondary placeholder-neutral border-secondary bg-primary"
      placeholder=""
      v-model="artistStore.onboardingFormData.gig_prefs"
      aria-label="Gig Preferences"
    ></textarea>
  </label>

  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Portfolio & Links</span>
    </div>
    <textarea
      class="textarea textarea-bordered w-full max-w-xs h-30 grow text-secondary placeholder-neutral border-secondary bg-primary"
      placeholder=""
      v-model="artistStore.onboardingFormData.links"
      aria-label="Portfolio & Links"
    ></textarea>
  </label>
</template>

<script setup>
import { useArtistStore } from '@/stores/artist'

const artistStore = useArtistStore()
</script>
