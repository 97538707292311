import './assets/app.css'

import { createApp } from 'vue'

import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as Sentry from '@sentry/vue'
import Vue3Toastify from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

import App from './App.vue'
import router from './router'
import { setupAuthStorePersistence } from './stores/auth'
import { setupArtistStorePersistence } from './stores/artist'
import { setupVenueStorePersistence } from './stores/venue'
import { setupVendorStorePersistence } from './stores/vendor'

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration({
      router,
      tracingOrigins: ['localhost', import.meta.env.VITE_APP_DOMAIN],
      shouldCreateSpanForRequest(url) {
        // Disable tracing for API calls
        return !url.includes('cms.ashor.live')
      }
    })
  ],
  // Tracing
  tracesSampleRate: 1.0, // You can reduce this to control tracing frequency, e.g., 0.5
  // Session Replay
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  // Track components
  trackComponents: true,
  hooks: ['activate', 'create', 'unmount', 'mount', 'update'],
  telemetry: false
})

const pinia = createPinia()
app.use(pinia)

setupAuthStorePersistence()
setupArtistStorePersistence()
setupVenueStorePersistence()
setupVendorStorePersistence()

app.use(router)

app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

app.use(Vue3Toastify, {
  theme: 'colored',
  autoClose: 3000
})

// app.config.warnHandler = (msg, instance, trace) =>
//   !['Google Maps JavaScript API has been loaded directly without loading=async'].some((warning) =>
//     msg.includes(warning)
//   ) && console.warn('[Vue warn]: '.concat(msg).concat(trace))

app.mount('#app')
