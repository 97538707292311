import { defineStore } from 'pinia'
import axios from 'axios'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    loadingData: false,
    userData: JSON.parse(localStorage.getItem('user_data')) || null,
    token: localStorage.getItem('auth_token') || null,
    entityTypeOpts: ['Artist', 'Venue', 'Vendor'],
    selectedEntityType: null
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
    entityType: (state) => state.userData?.entity_type
  },
  actions: {
    logout() {
      this.token = null
      this.userData = null

      localStorage.clear()
    },
    setUserData(data) {
      this.userData = data
      // localStorage.setItem('user_id', this.userData.id)

      // if (this.userData.email) {
      //   localStorage.setItem('email', this.userData.email)
      // }
    },
    setTokenUserData(data) {
      this.token = data.jwt
      this.setUserData(data.user)
    },
    loadCachedUserData() {
      const storedUserData = localStorage.getItem('user_data')
      if (storedUserData) {
        this.userData = JSON.parse(storedUserData)
      }

      const storedToken = localStorage.getItem('auth_token')
      if (storedToken) {
        this.token = storedToken
      }
    },
    async login(identifier, password) {
      try {
        this.loadingData = true

        const response = await axios.post(`${import.meta.env.VITE_APP_API_URL}/auth/local`, {
          identifier,
          password
        })

        console.log(response.data)
        this.setTokenUserData(response.data)
      } catch (error) {
        console.error('Login failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      } finally {
        this.loadingData = false
      }
    },
    async register(email, password) {
      try {
        this.loadingData = true

        const response = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/auth/local/register`,
          {
            username: email,
            email,
            password
          }
        )
        console.log(response.data)
        this.setTokenUserData(response.data)
      } catch (error) {
        console.error('Registration failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      } finally {
        this.loadingData = false
      }
    },
    selectEntityType(entityType) {
      this.selectedEntityType = entityType.toLowerCase()
    },
    async onboardingSubmitPressed() {
      console.log(this.selectedEntityType)
      try {
        this.loadingData = true

        const response = await axios.put(
          `${import.meta.env.VITE_APP_API_URL}/users/${this.userData?.id}`,
          {
            entity_type: this.selectedEntityType
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          }
        )
        console.log(response.data)

        this.setUserData(response.data)
      } catch (error) {
        console.error('Entity Type Update failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      } finally {
        this.loadingData = false
      }
    }
  }
})

// Sync state changes to localStorage
export function setupAuthStorePersistence() {
  const authStore = useAuthStore()

  authStore.$subscribe((mutation, state) => {
    if (state.token) {
      localStorage.setItem('auth_token', state.token)
    }

    if (state.userData) {
      localStorage.setItem('user_data', JSON.stringify(state.userData))
    }
  })
}
