<template>
  <div class="navbar bg-primary">
    <div class="navbar-start">
      <div class="flex-1" @click="redirectToHome">
        <img :src="logo" alt="logo" class="h-6 w-6 ml-3" />
      </div>
    </div>
    <div class="navbar-center">
      <!-- <a class="btn btn-ghost text-secondary text-xl">Ashor</a> -->
    </div>
    <div class="navbar-end">
      <div class="dropdown dropdown-end">
        <div tabindex="0" role="button" class="btn btn-ghost">
          <div class="w-6">
            <IconMenu />
          </div>
        </div>
        <ul
          tabindex="0"
          class="menu menu-sm dropdown-content bg-secondary rounded-box z-[1] mt-3 w-42 shadow p-2"
        >
          <li>
            <a class="justify-between" @click="redirectToExplore">
              <IconEye class="text-primary" />
              Explore
            </a>
          </li>
          <li v-if="authStore.isAuthenticated">
            <a class="justify-between" @click="redirectToOnboard">
              <IconUser class="text-primary" />
              Profile
            </a>
          </li>
          <li v-if="authStore.isAuthenticated">
            <a class="justify-between" @click="openLogoutModal">
              <IconExit />
              Logout
            </a>
          </li>
          <li v-if="!authStore.isAuthenticated">
            <a class="justify-between" @click="redirectToLogin">
              <IconUser class="text-primary" />
              Login
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <ModalBase
    :isOpen="isLogoutModalOpen"
    title="Just Checking..."
    :showConfirm="true"
    closeText="Cancel"
    confirmText="Log Out"
    @close="closeLogoutModal"
    @confirm="handleLogout"
  >
    <div class="flex flex-col gap-2 text-center">
      <p>
        Logging out will end your current session.<br />
        Do you want to proceed?
      </p>
    </div>
  </ModalBase>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import logo from '@/assets/logo.png'
import IconMenu from './icons/IconMenu.vue'
import IconExit from './icons/IconExit.vue'
import IconUser from './icons/IconUser.vue'
import IconEye from './icons/IconEye.vue'

import ModalBase from './ModalBase.vue'

import { useAuthStore } from '@/stores/auth'

const router = useRouter()
const authStore = useAuthStore()

const isLogoutModalOpen = ref(false)

const redirectToHome = () => {
  router.push({
    name: 'home'
  })
}

const redirectToExplore = () => {
  router.push({
    name: 'explore'
  })
}

const redirectToLogin = () => {
  router.push({
    name: 'login'
  })
}

const redirectToOnboard = () => {
  router.push({
    name: 'onboard'
  })
}

const openLogoutModal = () => {
  isLogoutModalOpen.value = true
}

const closeLogoutModal = () => {
  isLogoutModalOpen.value = false
}

const handleLogout = () => {
  authStore.logout()
  closeLogoutModal()
  redirectToLogin()
}
</script>
