<template>
  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Performance Style *</span>
    </div>
    <div class="w-full max-w-xs grow join join-vertical lg:join-horizontal flex lg:items-center">
      <button
        v-for="style in performanceStyles"
        :key="style"
        class="btn btn-secondary join-item btn-md"
        :class="{
          'btn-outline': artistStore.onboardingFormData.performance_style !== style.toLowerCase()
        }"
        @click="artistStore.selectPerformanceStyle(style)"
      >
        {{ style }}
      </button>
    </div>
  </label>
  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Experience (In Years) *</span>
    </div>
    <input
      type="number"
      min="0"
      class="input input-bordered w-full max-w-xs grow text-secondary placeholder-neutral border-secondary"
      placeholder=""
      v-model="artistStore.onboardingFormData.exp_years"
      aria-label="Experience (In Years)"
    />
  </label>
  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Number of Shows *</span>
    </div>
    <input
      type="number"
      min="0"
      class="input input-bordered w-full max-w-xs grow text-secondary placeholder-neutral border-secondary"
      placeholder=""
      v-model="artistStore.onboardingFormData.num_shows"
      aria-label="Number of Shows"
    />
  </label>
  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Genre(s)</span>
    </div>
    <vue3-tags-input
      class="w-full max-w-xs grow tags-input"
      :tags="artistStore.genreTags"
      placeholder=""
      @on-tags-changed="artistStore.genreTagsUpdated"
    />
  </label>
  <label class="w-full max-w-xs">
    <div class="label">
      <span class="label-text text-secondary">Location *</span>
    </div>
    <GoogleAutocomplete
      class="input input-bordered w-full max-w-xs grow text-secondary placeholder-neutral border-secondary"
      placeholder="Enter Address"
      v-model="artistStore.onboardingFormData.address"
      :api-key="apiKey"
      @set="artistStore.updateAddressData($event)"
    />
    <div class="label" v-if="artistStore.artistData?.address">
      <span class="label-text text-neutral"
        >Current Address: {{ artistStore.artistData?.address }}</span
      >
    </div>
  </label>
</template>

<style scoped>
.tags-input {
  background-color: #ff9c40;
  color: white;
  border-color: white;
  border-radius: 0.5rem;
}

::v-deep .v3ti-tag {
  background-color: #ff9c40 !important;
  color: white;
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
}

::v-deep .v3ti-remove-tag {
  color: white;
  margin-left: 0.25rem;
  cursor: pointer;
}
</style>

<script setup>
import { useArtistStore } from '@/stores/artist'
import { ref } from 'vue'
import { GoogleAutocomplete } from 'vue3-google-autocomplete'
import Vue3TagsInput from 'vue3-tags-input'

const artistStore = useArtistStore()

const apiKey = import.meta.env.VITE_APP_GMAP_API_KEY
const performanceStyles = ref(['Solo', 'Duo', 'Band', 'Studio'])
</script>
