import { defineStore } from 'pinia'
import axios from 'axios'

export const useTicketStore = defineStore('ticket', {
  state: () => ({
    ticketId: '',
    ticketData: null,
    loadingData: true
  }),
  getters: {
    ticketNotFound: (state) => {
      return state.ticketData === null && state.loadingData == false
    },
    ticketStatus: (state) => {
      return state.ticketData?.payment_status?.toUpperCase() || 'N/A'
    },
    statusStyle: (state) => {
      const status = state.ticketData?.payment_status?.toLowerCase() || ''
      return status === 'paid' ? 'text-success' : 'text-error'
    }
  },
  actions: {
    async fetchTicketData(ticketId) {
      this.loadingData = true
      this.ticketId = ticketId

      try {
        const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/tickets`, {
          params: {
            filters: {
              ticket_id: {
                $eq: ticketId
              }
            }
          },
          headers: {
            'Content-Type': 'application/json'
          }
        })

        if (response.data.data.length) {
          this.ticketData = response.data.data[0]
        } else {
          this.ticketData = null
        }
      } catch (error) {
        console.error('Error loading ticket data:', error)
      } finally {
        this.loadingData = false
      }
    }
  }
})
