<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center gap-2"
  >
    <h1 class="antialiased text-secondary text-center text-3xl md:text-4xl mt-3">Artist Profile</h1>
    <progress
      class="progress progress-secondary w-56 mb-3 mt-6"
      :value="artistStore.onboardingProgress"
      max="100"
    ></progress>
    <OnboardingFirstStep v-if="artistStore.currentOnboardingState === 1" />
    <OnboardingSecondStep v-if="artistStore.currentOnboardingState === 2" />
    <OnboardingThirdStep v-if="artistStore.currentOnboardingState === 3" />
    <div class="mt-6 flex flex-row gap-3">
      <button
        v-if="!artistStore.onFirstOnboardingState"
        class="btn md:btn-md btn-outline btn-secondary mt-2"
        @click="artistStore.profileBackPressed"
        aria-label="Back"
      >
        Previous
      </button>
      <button
        class="btn md:btn-md btn-secondary mt-2"
        :disabled="artistStore.loadingData || !artistStore.submissionEnabled"
        @click="onSubmitPressed"
        aria-label="Submit"
      >
        {{ artistStore.onLastOnboardingState ? 'Submit' : 'Next' }}
      </button>
    </div>
    <div class="mt-6">
      <h4 class="antialiased text-neutral text-center text-sm">
        Need help?
        <a
          class="text-secondary cursor-pointer"
          :href="contactLink"
          target="_blank"
          rel="noopener noreferrer"
          >Contact Us</a
        >
      </h4>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useArtistStore } from '@/stores/artist'
import { toast } from 'vue3-toastify'

import OnboardingFirstStep from '@/components/artist/OnboardingFirstStep.vue'
import OnboardingSecondStep from '@/components/artist/OnboardingSecondStep.vue'
import OnboardingThirdStep from '@/components/artist/OnboardingThirdStep.vue'

const router = useRouter()
const artistStore = useArtistStore()

const contactLink = computed(() => {
  return `https://wa.me/${import.meta.env.VITE_APP_WA_CONTACT}`
})

onMounted(async () => {
  if (artistStore.artistData?.id) {
    await artistStore.fetchArtistProfileData()
  }
})

const onSubmitPressed = async () => {
  try {
    const submitted_data = await artistStore.profileSubmitPressed()
    if (submitted_data) {
      router.push({
        name: 'artist-profile-detail'
      })
    }
  } catch (e) {
    toast.error(e.response?.data?.error.message)
  }
}
</script>
