import { defineStore } from 'pinia'
import axios from 'axios'

import { useAuthStore } from './auth'

export const useArtistStore = defineStore('artist', {
  state: () => ({
    loadingData: false,
    totalOnboardingStates: 3,
    currentOnboardingState: parseInt(localStorage.getItem('current_onboarding_state')) || 1,
    profilePhotoFile: null,
    profileReels: null,
    onboardingFormData: JSON.parse(localStorage.getItem('artist_data')) || {
      stage_name: '',
      full_name: '',
      email: '',
      phone: '',
      performance_style: 'solo',
      exp_years: 0,
      num_shows: 0,
      gig_prefs: '',
      genre: '',
      price_range_min: 10,
      price_range_max: 10000,
      available: true,
      links: '',
      location_lat: '',
      location_lon: '',
      address: ''
    },
    artistData: JSON.parse(localStorage.getItem('artist_data')) || null,
    artists: []
  }),
  getters: {
    onboardingProgress: (state) => {
      const pcnt = (100 / state.totalOnboardingStates) * state.currentOnboardingState
      return Math.round(pcnt)
    },
    onFirstOnboardingState: (state) => {
      return state.currentOnboardingState === 1
    },
    onLastOnboardingState: (state) => {
      return state.currentOnboardingState === state.totalOnboardingStates
    },
    genreTags: (state) => {
      return state.onboardingFormData?.genre ? state.onboardingFormData.genre.split(', ') : []
    },
    isPhoneValid: (state) => {
      return /^(?:\+88)?01[3-9][0-9]{8}$/.test(state.onboardingFormData.phone)
    },
    isEmailValid: (state) => {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(state.onboardingFormData.email)
    },
    profileDataUpdated: (state) => {
      return JSON.stringify(state.artistData) !== JSON.stringify(state.onboardingFormData)
    },
    submissionEnabled: (state) => {
      // const needsUpdating = state.onLastOnboardingState && state.profileDataUpdated
      return state.validateOnboarding() //&& needsUpdating
    },
    profilePhotoUrl: (state) => {
      return state.artistData?.profile_photo?.formats
        ? import.meta.env.VITE_APP_BASE_URL + state.artistData.profile_photo.formats.small.url
        : state.artistData?.profile_photo
          ? state.artistData?.profile_photo.url
          : null
    },
    profilePhotoFileUrl: (state) => {
      return state.profilePhotoFile ? URL.createObjectURL(state.profilePhotoFile) : null
    }
  },
  actions: {
    async uploadFile(file, refId, ref, field, path = null) {
      this.loadingData = true

      const authStore = useAuthStore()

      try {
        const formData = new FormData()

        formData.append('files', file)

        // Add additional metadata for the file association
        formData.append('refId', refId) // ID of the related entry (e.g., user ID)
        formData.append('ref', ref) // Model name (e.g., user, artist, ticket)
        formData.append('field', field) // Field where the file will be stored (e.g., profile_photo)

        if (path) {
          formData.append('path', path) // Custom path for file upload
        }

        const response = await axios.post(`${import.meta.env.VITE_APP_API_URL}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authStore.token}`
          }
        })

        console.log('File upload response:', response.data)
        return response.data
      } catch (error) {
        console.error('File upload failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      } finally {
        this.loadingData = false
      }
    },
    async createArtistProfile() {
      const authStore = useAuthStore()

      try {
        this.loadingData = true

        const response = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/artists`,
          {
            data: {
              ...this.onboardingFormData,
              created_user: authStore.userData?.id
            }
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`
            }
          }
        )
        // console.log(response.data)

        this.artistData = response.data.data
      } catch (error) {
        console.error('Artist Creation failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      } finally {
        this.loadingData = false
      }
    },
    async updateArtistProfile() {
      const authStore = useAuthStore()

      try {
        this.loadingData = true

        const response = await axios.put(
          `${import.meta.env.VITE_APP_API_URL}/artists/${this.artistData?.id}`,
          {
            data: {
              ...this.onboardingFormData
            }
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`
            }
          }
        )
        console.log(response.data)

        this.artistData = response.data.data
      } catch (error) {
        console.error('Artist Update failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      } finally {
        this.loadingData = false
      }
    },
    async fetchAllArtists() {
      try {
        const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/artists`, {
          params: {
            populate: '*'
          }
        })
        console.log(response.data)
        this.artists = response.data.data
        return this.artists
      } catch (error) {
        console.error('Artists Fetch failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      }
    },
    async fetchArtistProfileData() {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_APP_API_URL}/artists/${this.artistData?.id}`,
          {
            params: {
              populate: '*'
            }
          }
        )
        console.log(response.data)
        this.artistData = response.data.data
        this.onboardingFormData = { ...this.artistData }
        return this.artistData
      } catch (error) {
        console.error('Artist Fetch failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      }
    },
    async fetchArtistProfileByCreatedUser(userId) {
      const authStore = useAuthStore()

      try {
        const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/artists/`, {
          headers: {
            Authorization: `Bearer ${authStore.token}`
          },
          params: {
            populate: '*',
            filters: {
              created_user: {
                id: {
                  $eq: userId // Check for equality with the user ID
                }
              }
            }
          }
        })
        console.log(response.data)
        if (response.data.data.length) {
          this.artistData = response.data.data[0]
          this.onboardingFormData = { ...this.artistData }
        }
        return this.artistData
      } catch (error) {
        console.error('Artist Fetch failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      }
    },
    async fetchArtistProfileBySlug(slug) {
      try {
        const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/artists/`, {
          params: {
            populate: '*',
            filters: {
              slug: {
                $eq: slug
              }
            }
          }
        })
        console.log(response.data)
        if (response.data.data.length) {
          this.artistData = response.data.data[0]
          // this.onboardingFormData = { ...this.artistData }
        }
        return this.artistData
      } catch (error) {
        console.error('Artist Fetch failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      }
    },
    getOnboardingRequiredFields() {
      switch (this.currentOnboardingState) {
        case 1:
          return ['stage_name', 'full_name', 'email', 'phone']
        case 2:
          return ['exp_years', 'num_shows', 'address']
        case 3:
          return ['price_range_min', 'price_range_max']
        default:
          return []
      }
    },
    validateOnboarding() {
      const reqFields = this.getOnboardingRequiredFields()
      for (const field of reqFields) {
        if (!this.onboardingFormData[field]) {
          return false
        }
      }

      if (this.currentOnboardingState == 1) {
        return this.isEmailValid && this.isPhoneValid
      }

      return true
    },
    async profileSubmitPressed() {
      if (!this.onLastOnboardingState) {
        this.currentOnboardingState += 1
        return false
      }

      console.log('submit data api : ' + this.artistData === null ? 'created' : 'updated')
      try {
        if (this.artistData === null) {
          await this.createArtistProfile()
        } else {
          await this.updateArtistProfile()
        }

        this.currentOnboardingState = 1
        return true
      } catch (e) {
        return false
      }
    },
    async uploadProfilePhoto() {
      await this.uploadFile(
        this.profilePhotoFile,
        this.artistData?.id,
        'api::artist.artist',
        'profile_photo'
      )

      if (this.artistData?.id) {
        await this.fetchArtistProfileData()
      }
    },
    profileBackPressed() {
      if (this.currentOnboardingState <= 1) {
        return
      }
      this.currentOnboardingState -= 1
    },
    selectPerformanceStyle(style) {
      this.onboardingFormData.performance_style = style.toLowerCase()
    },
    updateAddressData(data) {
      this.onboardingFormData.location_lat = data.latitude
      this.onboardingFormData.location_lon = data.longitude

      var addressParts = [data.name, data.city, data.country].filter(Boolean)
      addressParts = Array.from(new Set(addressParts))
      this.onboardingFormData.address = addressParts.map((str) => str.trim()).join(', ')
    },
    selectProfilePhoto(file) {
      this.profilePhotoFile = file
    },
    unsetSelectProfilePhoto() {
      this.profilePhotoFile = null
    },
    selectReels(files) {
      this.profileReels = files
    },
    genreTagsUpdated(tags) {
      this.onboardingFormData.genre = tags.join(', ')
    }
  }
})

// Sync state changes to localStorage
export function setupArtistStorePersistence() {
  const store = useArtistStore()

  store.$subscribe((mutation, state) => {
    if (state.artistData) {
      localStorage.setItem('artist_data', JSON.stringify(state.artistData))
    }

    localStorage.setItem('current_onboarding_state', state.currentOnboardingState)

    // if (state.onboardingFormData) {
    //   localStorage.setItem('onboarding_form_data', JSON.stringify(state.onboardingFormData))
    // }
  })
}
